import global from "../../../../constants/global"
import images from "../../../../assets/images"
import animation from "../../../../constants/animation"


const ANIMATION = {...animation.LEFT_APPEARING(10)}

const HEADER_TYPES = {
    'information' : {
        title: 'Informationen',
        img: {
            src: images.IconInfo,
            alt: 'info'
        }
    },
    'error': {
        title: 'Fehler',
        img: {
            src: '',
            alt: 'error'
        }
    },
    'warning': {
        title: 'Warnung',
        img: {
            src: '',
            alt: 'warning'
        }
    }
}

const DEFAULT_TYPE = global.SUPPORT_HEADERS[0]

export default {
    HEADER_TYPES,
    DEFAULT_TYPE,
    ANIMATION
}