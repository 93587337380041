import { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import { UnavailablePage } from "../../../pages/UnavailablePage/UnavailablePage";
import { HomePage } from "../../../pages/HomePage/HomePage";
import { PostsListPage } from "../../../pages/PostsListPage/PostsListPage";
import { PostPage } from "../../../pages/PostPage/PostPage";
import { FaqPage } from "../../../pages/FaqPage/FaqPage";
import { AgileMethodikPage } from "../../../pages/AgileMethodikPage/AgileMethodikPage";
import { LoginPage } from "../../../pages/LoginPage/LoginPage";
import { RegisterPage } from "../../../pages/RegisterPage/RegisterPage";
import { ResetPasswordPage } from "../../../pages/ResetPasswordPage/ResetPasswordPage";

const Router = ({ setActiveMenuItem, setHeaderType, setFooterType }) => {
  const groupList = useRef([
    {
      conidtion: false,
      routes: (
        <Route
          exact
          path="*"
          element={<UnavailablePage setHeaderType={setHeaderType} />}
        />
      ),
    },
    {
      conidtion: true,
      routes: (
        <>
          <Route
            exact
            path="*"
            element={<UnavailablePage setHeaderType={setHeaderType} />}
          />
          <Route
            exact
            path="/"
            element={
              <HomePage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          <Route
            exact
            path="/wissen"
            element={
              <PostsListPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          <Route
            exact
            path="/faq"
            element={
              <FaqPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          <Route
            exact
            path="/agil-methodik"
            element={
              <AgileMethodikPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          {/* <Route exact path="/projekte-im-programm" element={<PostsListPage setActiveMenuItem={setActiveMenuItem}/>} /> */}
          <Route
            exact
            path="/wissen/:slug"
            element={
              <PostPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          <Route
            exact
            path="/wissen/tag/:tag"
            element={
              <PostsListPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          <Route
            exact
            path="/login"
            element={<LoginPage setHeaderType={setHeaderType} setFooterType={setFooterType}/>}
          />
           <Route
            exact
            path="/registration"
            element={<RegisterPage setHeaderType={setHeaderType} setFooterType={setFooterType}/>}
          />
          <Route
            exact
            path="/reset-password"
            element={<ResetPasswordPage setHeaderType={setHeaderType} setFooterType={setFooterType}/>}
          />
        </>
      ),
    },
  ]);

  const chooseRouteGroup = (groups) => {
    const defaultRoute = groups[0].routes;
    if (Array.isArray(groups) && groups.length) {
      const chosenRoutesGroup = groups.reduce((result, group) => {
        if (group.conidtion) {
          return group.routes;
        }
        return result;
      }, defaultRoute);
      return chosenRoutesGroup;
    }
    return defaultRoute;
  };

  return <Routes>{chooseRouteGroup(groupList.current)}</Routes>;
};

Router.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func.isRequired,
};

Router.defaultProps = {
  setActiveMenuItem: () => {},
  setHeaderType: () => {},
};

export { Router };
