import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useMenu from "../../hooks/menu/menu";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import constants from "./constants";

const AgileMethodikPage = ({ setActiveMenuItem, setHeaderType }) => {
  const { resetMenu } = useMenu();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    resetMenu();
    setActiveMenuItem(constants.PAGE_ID);
    setHeaderType(constants.HEADER_TYPE);
    try {
      //server querry
    } catch (e) {
      //error helding
    } finally {
      setIsLoading(false);
    }
  });
  return isLoading && <Preloader />;
};

AgileMethodikPage.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func,
};

AgileMethodikPage.defaultProps = {
  setActiveMenuItem: () => {},
  setHeaderType: () => {},
};
export { AgileMethodikPage };
