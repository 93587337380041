import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { Link } from "react-router-dom";
import { SupportingHead } from "../SimpleComponents/SupportingHead/SupportingHead";
import { TagButton } from "../Buttons/TagButton/TagButton";
import utils from "../../../utils";
import constants from "./constants";
import styles from "./card.module.scss";
import { Bookmark } from "../SimpleComponents/Bookmark/Bookmark";

const Card = ({
  id,
  slug,
  title,
  subtitle,
  image,
  supportType,
  description,
  date,
  userFavorites,
  userId,
}) => {
  const imgContainerRef = useRef(null);
  const [cardHoverBg, setCardHoverBg] = useState("#ffffff");
  const [hoverState, setHoverState] = useState(false);
  const [usersFavorite, setUsersFavorite] = useState([]);

  const calcHeightImage = () => {
    if (imgContainerRef.current) {
      imgContainerRef.current.style.height = `${
        (imgContainerRef.current.getBoundingClientRect().width / 512) * 296.0
      }px`;
    }
  };
  useEffect(() => {
    setCardHoverBg(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--card-bg-color"
      )
    );
    calcHeightImage();
    window.addEventListener("resize", calcHeightImage);
    return () => {
      window.removeEventListener("resize", calcHeightImage);
    };
  }, []);
  useEffect(() => {
    if (id) {
      console.log(
        id,
        slug,
        title,
        subtitle,
        image,
        supportType,
        description,
        date,
        userFavorites,
        userId
      );
      setUsersFavorite(utils.text.convertStringToArray(userFavorites));
    }
  }, [id]);

  return (
    <motion.div
      {...constants.ANIMATIONS.card.active}
      whileHover={constants.ANIMATIONS.card.hover.whileHover(cardHoverBg)}
      className={classNames(styles.amCard)}
      onMouseLeave={() => setHoverState(false)}
      onMouseOver={() => setHoverState(true)}
    >
      {image && image.toString().trim() !== "" && (
        <Link to={`/wissen/${slug}`}>
          <motion.div
            className={classNames(styles.imgContainer)}
            ref={imgContainerRef}
          >
            <motion.div
              {...constants.ANIMATIONS.image}
              animate={hoverState ? "end" : "start"}
            >
            <img src={image} alt={title}/>
            </motion.div>
          </motion.div>
        </Link>
      )}
      <motion.div className={classNames(styles.infos)}>
        {supportType && <SupportingHead type={supportType} />}
        {subtitle && (
          <motion.h3
            {...constants.ANIMATIONS.subtitle}
            className={classNames(styles.subtitle)}
          >
            {utils.text.decodeHtmlCharCodes(subtitle)}
          </motion.h3>
        )}
        {console.log(title)}
        <motion.h3
          {...constants.ANIMATIONS.title}
          className={classNames(
            styles.title,
            subtitle ? styles.withSubtitle : styles.withoutSubtitle
          )}
        >
          <Link to={`/wissen/${slug}`}>
            {utils.text.decodeHtmlCharCodes(title)}
          </Link>
        </motion.h3>
        <motion.p
          {...constants.ANIMATIONS.description}
          className={classNames(styles.description)}
        >
          {utils.text.decodeHtmlCharCodes(description)}
        </motion.p>
        <motion.div className={classNames(styles.addInfos)}>
          <motion.div>
            <motion.p>{moment(date).format("D. MMMM")}</motion.p>
            <TagButton title="Button Title" />
          </motion.div>
          <Bookmark
            postId={id}
            userId={userId}
            bookmarked={usersFavorite.includes(userId)}
            successCallback={(v) => setUsersFavorite(v)}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

Card.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  supportType: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  userFavorites: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

export { Card };
