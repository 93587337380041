const ARTICLE_PREVIEW = "/wp-json/article/v1/preview";
const ARTICLES_LATEST = "/wp-json/article/v1/latest";
const ARTICLE_TAGS = "/wp-json/article/v1/tags";
const ARTICLE_SINGLE = "/wp-json/article/v1/single";
const ARTICLE_FAVORITE = "/wp-json/article/v1/favorite";
const ARTICLE_LIKE = "/wp-json/article/v1/like";
const LOGIN = "/wp-json/jwt-auth/v1/token";
const REGISTER = "/wp-json/accaunt/v1/register";
const RESET_PASWORD = "/wp-json/accaunt/v1/reset-password";

const custom = {
  ARTICLE_PREVIEW,
  ARTICLES_LATEST,
  ARTICLE_TAGS,
  ARTICLE_SINGLE,
  ARTICLE_FAVORITE,
  ARTICLE_LIKE,
  LOGIN,
  REGISTER,
  RESET_PASWORD,
};

const standart = {};

export default {
  custom,
  standart,
};
