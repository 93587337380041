import global from "../../constants/global"

const REMEMBER_ME_LABEL = 'Login-Daten für die Zukunft speichern'

const FIELDS = [
  {
    name: 'username',
    type: 'text',
    placeholder: 'Email',
    default: ''
    // default: utils.user.getSavedEmail(),
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Passwort',
    default: ''
    // default: utils.user.getSavedPassword(),
  },
  {
    name: 'rememberme',
    type: 'checkbox',
    default: '',
    value: REMEMBER_ME_LABEL,
    placeholder: REMEMBER_ME_LABEL,
  },
]

const BUTTON_NAME = "Anmeldung"
const SUBTITLE = "BITTE GEBEN SIE IHRE ANMELDEDATEN FÜR DAS WIKI EIN:"

const LINKS = [
  `Haben Sie Ihr<a href="/reset-password"> Passwort vergessen?</a>`,
  `Oder haben Sie noch kein Benutzerkonto? <a href="/register">Registrieren</a>`
]

const HEADER_TYPE = global.HEADER_TYPES[2];

const FOOTER_TYPE = global.FOOTER_TYPES[1]

export default {
    FIELDS,
    BUTTON_NAME,
    SUBTITLE,
    LINKS,
    HEADER_TYPE,
    FOOTER_TYPE
}