const MOST_COMMON_TRANSITION = (duration, delay = 0) => {
  return {
    delay: delay,
    duration: duration,
    transform: {
      type: "inertia",
      velocity: 125,
      duration: duration,
    },
  };
};

const FADEIN_DOWN_APPEARING = (duration = 1, y = -100) => {
  return {
    variants: {
      start: {
        opacity: 0,
        transform: `translateY(${y}px)`,
      },
      end: {
        opacity: 1,
        transform: "translateY(0px)",
      },
    },
    transition: (delay = 0) => {
      return MOST_COMMON_TRANSITION(duration, delay);
    },
  };
};

const FADEIN_APPEARING = (duration = 1) => {
  return {
    variants: {
      start: {
        opacity: 0,
      },
      end: {
        opacity: 1,
      },
    },
    transition: (delay = 0) => {
      return {
        delay: delay,
        duration: duration,
      };
    },
  };
};

const FADEIN_LEFT_APPEARANCE = (duration = 1) => {
  return {
    variants: {
      start: {
        opacity: 0,
        transform: "translateX(-200px)",
      },
      end: {
        opacity: 1,
        transform: "translateX(0px)",
      },
    },
    transition: (delay = 0) => {
      return MOST_COMMON_TRANSITION(duration, delay);
    },
  };
};

const DOWN_APPEARING = (duration = 1, y = -15) => {
  return {
    variants: {
      start: {
        maxHeight: 0,
        overflow: "hidden",
        transform: `translateY(${y}px)`,
      },
      end: {
        maxHeight: "50vh",
        overflow: "visible",
        transform: "translateY(0px)",
      },
    },
    transition: (delay = 0) => {
      return MOST_COMMON_TRANSITION(duration, delay);
    },
  };
};

const LEFT_APPEARING = (duration = 1) => {
  return {
    variants: {
      start: {
        maxWidth: "0",
      },
      end: {
        maxWidth: "100vh",
      },
    },
    transition: (delay = 0) => {
      return {
        type: "inertia",
        velocity: 125,
        delay: delay,
        duration: duration,
      };
    },
  };
};

const FADEIN_APPEARING_VIEWPORT = (x = 0, y = 0, duration = 1, delay = 0) => ({
  initial: { opacity: 0, x, y },
  whileInView: { opacity: 1, x: 0, y: 0 },
  viewport: { once: true },
  transition: { delay, duration },
});

const SCALE_ANIMATION = (
  initScale = 1,
  aniScale = 1.2,
  duration = 1,
  delay = 0
) => ({
  variants: { start: { scale: initScale }, end: { scale: aniScale } },
  transition: { delay, duration },
});

const ROTATE = (rotate, duration = 1) => {
  return {
    variants: {
      start: {
        rotate: 0,
      },
      end: {
        rotate,
      },
    },
    transition: (delay = 0) => {
      return {
        delay: delay,
        duration: duration,
      };
    },
  };
};

const HOVER_BACKGROUND_CHANGE = (duration = 1, delay = 0) => ({
  initial: (initBg = "#fff") => ({ backgroundColor: initBg }),
  whileHover: (hoverBg = "#ececec") => ({
    backgroundColor: hoverBg,
    transition: { delay, duration },
  }),
});

const HOVER_COLOR_CHANGE = (duration = 1, delay = 0) => ({
  initial: (initColor = "#fff") => ({ color: initColor }),
  whileHover: (hoverColor = "#ececec") => ({
    color: hoverColor,
    transition: { delay, duration },
  }),
});

const HOVER_SCALE_CHANGE = (hoverScale = 1.2, duration = 1, delay = 0) => ({
  whileHover: {
    scale: hoverScale,
    transition: { delay, duration },
  },
});

export default {
  FADEIN_DOWN_APPEARING,
  FADEIN_LEFT_APPEARANCE,
  DOWN_APPEARING,
  FADEIN_APPEARING,
  LEFT_APPEARING,
  FADEIN_APPEARING_VIEWPORT,
  HOVER_BACKGROUND_CHANGE,
  SCALE_ANIMATION,
  ROTATE,
  HOVER_COLOR_CHANGE,
  HOVER_SCALE_CHANGE,
};
