import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./bookmark.module.scss";
import constants from "./constants";
import utils from "../../../../utils";
import api from "../../../../api/server.api";
import crud from "../../../../api/crud";

const Bookmark = ({
  bookmarked,
  className,
  userId,
  postId,
  successCallback,
}) => {
  const [favoriteUsers, setFavoriteUsers] = useState(null);
  const headerWithAuth = utils.user.headerWithAuth();

  useEffect(() => {
    if (favoriteUsers) successCallback(favoriteUsers.map((v) => Number(v)));
  }, [favoriteUsers]);

  const onClickBookmark = async () => {
    try {
      await Promise.all([
        utils.api.postData(
          api.custom,
          crud.custom.ARTICLE_FAVORITE,
          setFavoriteUsers,
          { userId, postId },
          headerWithAuth
        ),
      ]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <motion.img
      className={classNames(styles.icon, className)}
      src={
        bookmarked
          ? constants.ICON_BOOKMARKS.bookmarked.src
          : constants.ICON_BOOKMARKS.unbookmarked.src
      }
      alt={
        bookmarked
          ? constants.ICON_BOOKMARKS.bookmarked.alt
          : constants.ICON_BOOKMARKS.unbookmarked.alt
      }
      {...constants.ANIMATIONS.icon}
      onClick={onClickBookmark}
    />
  );
};

Bookmark.propTypes = {
  bookmarked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  userId: PropTypes.number.isRequired,
  postId: PropTypes.number.isRequired,
  successCallback: PropTypes.func,
};

Bookmark.defaultProps = {
  className: "",
  successCallback: () => {},
};

export { Bookmark };
