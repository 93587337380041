import * as yup from 'yup'
import constants from './constants'

const loginRegExp = '^[a-zA-Z0-9_\\-@.]+$'

const passwordsregexp =
  '^.*(?=.{12,})(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()]?).*$'

const notOnlySymbols = '^.*[^\\.\\-\\s].*$'
const excludedSymbols = '^[^><&!"№;%:\\?\\*\\(\\)_\\+=\\-,\\.\\\\]+$'  

const userLoginFormSchems = yup.object().shape({
    username: yup
      .string()
      .matches(loginRegExp, constants.LOGIN_INCORRECT_FORM)
      .required(constants.REQUIRED_FIELD),
    password: yup.string().required(constants.REQUIRED_FIELD),
  })

  const userRegisterFormSchems = yup.object().shape({
    username: yup
      .string()
      .min(3, constants.MIN_LENGTH)
      .max(20, constants.MAX_LENGTH)
      .matches(notOnlySymbols, constants.TITLE_NOT_ONLY_SYMBOLS)
      .matches(excludedSymbols, constants.UNAVALIBLE_SYMBOLS)
      .required(constants.REQUIRED_FIELD),
    password: yup
      .string()
      .matches(passwordsregexp, constants.PASSWORD)
      .required(constants.REQUIRED_FIELD),
    repeatpassword: yup
      .string()
      .oneOf([yup.ref('password'), null], constants.PASSWORDS_DONT_MATCH),
    email: yup.string().email(constants.EMAIL).required(),
  })
  
  const userResetPasswordFormSchems = yup.object().shape({
    username: yup
      .string()
      .email(constants.EMAIL)
      .required(constants.REQUIRED_FIELD),
  })

export default {
    userLoginFormSchems,
    userRegisterFormSchems,
    userResetPasswordFormSchems
}