import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Preloader } from "../../shared/components/Preloader/Preloader";
import constants from "./constants";

export const UnavailablePage = ({ setHeaderType }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setHeaderType(constants.HEADER_TYPE);
    try {
      //server querry
    } catch (e) {
      //error helding
    } finally {
      setIsLoading(false);
    }
  }, []);

  return isLoading && <Preloader />;
};

UnavailablePage.propTypes = {
  setHeaderType: PropTypes.func,
};

UnavailablePage.defaultProps = {
  setHeaderType: () => {},
};
