import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import classNames from "classnames";
import { useSelector } from "react-redux";

import useRedirects from "../../hooks/redirects/redirects";
import useMenu from "../../hooks/menu/menu";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import utils from "../../utils";
import api from "../../api/server.api";
import crud from "../../api/crud";
import constants from "./constants";
import styles from "./postPage.module.scss";
import { Container } from "../../shared/components/Markup/Container/Container";
import { Row } from "../../shared/components/Markup/Row/Row";
import { Col } from "../../shared/components/Markup/Col/Col";
import { Sidebar } from "../../shared/components/Sidebar/Sidebar";
import { TagButton } from "../../shared/components/Buttons/TagButton/TagButton";
import { Share } from "../../shared/components/SimpleComponents/Share/Share";
import { Bookmark } from "../../shared/components/SimpleComponents/Bookmark/Bookmark";
import { Like } from "../../shared/components/SimpleComponents/Like/Like";
import { ArticleInfo } from "../../shared/components/SimpleComponents/ArticleInfo/ArticleInfo";

const PostPage = ({ setActiveMenuItem, setHeaderType }) => {
  const { resetMenu } = useMenu();
  const [tagList, setTagList] = useState([]);
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [article, setArticle] = useState(null);
  const [usersFavorite, setUsersFavorite] = useState([]);
  const [usersLike, setUsersLike] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.user);
  const { toLoginpage } = useRedirects();

  const userId = Number(utils.user.getUserID());

  const loadInitialData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        utils.api.getData(api.custom, crud.custom.ARTICLE_TAGS, setTagList),
        utils.api.getData(
          api.custom,
          `${crud.custom.ARTICLE_SINGLE}?slug=${slug}`,
          setArticle
        ),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    resetMenu();
    setActiveMenuItem(constants.PAGE_ID);
    setHeaderType(constants.HEADER_TYPE);
    if (!isLoggedIn) {
      toLoginpage();
    }
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [slug]);

  useEffect(() => {
    if (article) {
      setUsersFavorite(utils.text.convertStringToArray(article.userFavorites));
      setUsersLike(utils.text.convertStringToArray(article.userLikes));
    }
  }, [article]);

  return (
    <>
      {isLoading && <Preloader />}
      <motion.section className={classNames(styles.container)}>
        <Container>
          <Row>
            <Col numb={8}>
              {article && (
                <>
                  {article.thumbnail && (
                    <motion.div
                      {...constants.ANIMATIONS.thumbnail}
                      className={classNames(styles.thumbnail)}
                      dangerouslySetInnerHTML={{ __html: article.thumbnail }}
                    ></motion.div>
                  )}
                  <motion.h2
                    {...constants.ANIMATIONS.title}
                    className={classNames(styles.title)}
                    dangerouslySetInnerHTML={{ __html: article.title }}
                  ></motion.h2>
                  <motion.div className={classNames(styles.tags)}>
                    {article.tags.map((tag, index) => {
                      const tagClick = (e) => {
                        e.stopPropagation();
                        const link = e.target.querySelector("a");
                        if (link) {
                          link.click();
                        }
                      };
                      return (
                        <TagButton
                          key={index}
                          index={index}
                          title={
                            <Link to={`/wissen/tag/${tag.slug}`}>
                              {tag.title}
                            </Link>
                          }
                          opacityMode={true}
                          onClick={tagClick}
                        />
                      );
                    })}
                  </motion.div>
                  <motion.div
                    {...constants.ANIMATIONS.preview}
                    className={classNames(styles.preview)}
                  >
                    {article.preview}
                  </motion.div>
                  <motion.div
                    className={classNames(styles.content)}
                    dangerouslySetInnerHTML={{ __html: article.content }}
                  ></motion.div>
                  <motion.div className={classNames(styles.favoriteContainer)}>
                    <Share text={constants.TXT_SHARE} />
                    <Bookmark
                      postId={article.id}
                      userId={userId}
                      bookmarked={usersFavorite.includes(userId)}
                      successCallback={(v) => setUsersFavorite(v)}
                    />
                  </motion.div>
                  <Like
                    postId={article.id}
                    userId={userId}
                    liked={usersLike.includes(userId)}
                    className={classNames(styles.likeContainer)}
                    successCallback={(v) => setUsersLike(v)}
                  />
                  <motion.h2
                    {...constants.ANIMATIONS.bottomTitle}
                    className={classNames(styles.bottomTitle)}
                    dangerouslySetInnerHTML={{ __html: article.title }}
                  ></motion.h2>
                  <motion.div
                    {...constants.ANIMATIONS.info}
                    className={classNames(styles.info)}
                  >
                    <ArticleInfo type="like" value={usersLike.length} />
                    <ArticleInfo type="comment" value={article.commentCount} />
                  </motion.div>
                </>
              )}
            </Col>
            <Col numb={4}>
              <Sidebar tags={tagList} />
            </Col>
          </Row>
        </Container>
      </motion.section>
    </>
  );
};

PostPage.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func,
};

PostPage.defaultProps = {
  setActiveMenuItem: () => {},
  setHeaderType: () => {},
};
export { PostPage };
