import LogoUkhd from "./logo-ukhd.png";
import LogoZdi from "./logo-zdi.png";
import IconAddBookmark from "./icon-addbookmark.svg";
import IconBookmarked from "./icon-bookmarked.svg";
import IconInfo from "./icon-info.png";
import IconArrowRight from "./icon-arrow-right.png";
import IconLikeBlue from "./icon-like-blue.svg";
import IconLikeGrey from "./icon-like-grey.svg";
import IconLikeFillWhite from "./icon-like-fill-white.svg";
import IconLikeFillGrey from "./icon-like-fill-grey.svg";
import IconLikeFillBlue from "./icon-like-fill-blue.svg";
import IconComment from "./icon-comment.svg";
import IconShare from "./icon-share.svg";

export default {
  IconAddBookmark,
  IconBookmarked,
  IconInfo,
  LogoUkhd,
  LogoZdi,
  IconArrowRight,
  IconLikeBlue,
  IconLikeGrey,
  IconLikeFillWhite,
  IconLikeFillGrey,
  IconLikeFillBlue,
  IconComment,
  IconShare,
};
