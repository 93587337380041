import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  motion,
  AnimatePresence,
  useAnimation,
  useInView,
} from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";

// import { useSelector } from "react-redux";
import { Container } from "../../Markup/Container/Container";
import { Row } from "../../Markup/Row/Row";
import { Menu } from "../Menu/Menu";
import { Col } from "../../Markup/Col/Col";
import { StandartButtons } from "../../Buttons/StandartButtons/StandartButtons";
import useRedirects from "../../../../hooks/redirects/redirects";
import { logout } from "../../../../features/user.operations";
import utils from "../../../../utils";
import constants from "./constants";
import styles from "./header.module.scss";

const Header = ({ menuItems, activeMenuItem, title, subtitle, type }) => {
  const controlls = useAnimation();
  // const { visibilityStatus } = useSelector((state) => state.menu);
  const header = useRef(null);
  const menuRef = useRef(null);
  const paddingTopRef = useRef(null);
  const isInView = useInView(header, { once: true });
  const { toLoginpage } = useRedirects()
  const dispatch = useDispatch()

  const invokeAnimation = (inViewStatus) => {
    controlls.set("start");
    if (inViewStatus) {
      controlls.start("end");
    }
  };

  const onScroll = () => {
    if (menuRef) {
      if (window.scrollY > menuRef.current.getBoundingClientRect().top) {
        menuRef.current.style.position = "fixed";
        paddingTopRef.current.style.paddingTop = "51px";
      } else {
        menuRef.current.style.position = "relative";
        paddingTopRef.current.style.paddingTop = "0px";
      }
    }
  };

  const logOut = ()=>{
      utils.user.resetAllData();
      dispatch(logout()).then(() => {
        console.log('logout correct')
        toLoginpage();
      });
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    invokeAnimation(isInView);
  }, [title, isInView]);

  return (
    type != "noheader" && (
      <section className={classNames(styles.container)} ref={header}>
        <motion.div
          className={classNames(
            styles.paddingTop,
            type === constants.HEADER_TYPES[1] && styles.tiny
          )}
          ref={paddingTopRef}
        ></motion.div>
        <div ref={menuRef} className={classNames(styles.menuContainer)}>
          <Container className={styles.markup__container}>
            <Row>
              <Col numb={11}>
                {/* {visibilityStatus && ( */}
                <Menu items={menuItems} activeId={activeMenuItem} />
                {/* )} */}
              </Col>
              <Col numb={1}>
                <StandartButtons title={"Ausgang"} onClick={logOut}></StandartButtons>
              </Col>
            </Row>
          </Container>
        </div>
        <Container
          className={
            type === constants.HEADER_TYPES[1] ? styles.titleHeader : ""
          }
        >
          <Row>
            <AnimatePresence>
              <motion.h1
                variants={constants.ANIMATION.variants}
                initial="start"
                animate={"end"}
                transition={constants.ANIMATION.transition(.5)}
              >
                {title}
              </motion.h1>
            </AnimatePresence>
          </Row>
          <Row>
            <AnimatePresence>
              <motion.div
                variants={constants.ANIMATION.variants}
                initial="start"
                exit="start"
                animate={controlls}
                transition={constants.ANIMATION.transition(
                  constants.SUBTITLE_ANIMATION_DELAY
                )}
                className={styles.subtitle}
              >
                {subtitle}
              </motion.div>
            </AnimatePresence>
          </Row>
        </Container>
        <motion.div
          className={classNames(
            styles.paddingBottom,
            type === constants.HEADER_TYPES[1] && styles.tiny
          )}
        ></motion.div>
      </section>
    )
  );
};

Header.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeMenuItem: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  resetMenu: PropTypes.bool,
  setResetMenu: PropTypes.func,
  type: PropTypes.oneOf(constants.HEADER_TYPES),
};

Header.defaultProps = {
  menuItems: [],
  activeMenuItem: false,
  title: "",
  subtitle: "",
  resetMenu: false,
  setResetMenu: () => {},
  type: constants.HEADER_TYPES[0],
};

export { Header };
