import images from "../assets/images";

const PAGES_IDs = [
  "home",
  "wissen",
  "projekte-im-programm",
  "faq",
  "agile-methodik",
];

const SUPPORT_HEADERS = ["information", "error", "warning"];

const DEFAULT_ARTICLES_CATEGORY_ID = 1;

const ICON_BOOKMARKS = {
  unbookmarked: { src: images.IconAddBookmark, alt: "icon-add-bookmark" },
  bookmarked: { src: images.IconBookmarked, alt: "icon-bookmarked" },
};
const ICON_LIKE = {
  blue: { src: images.IconLikeBlue, alt: "icon-liked" },
  grey: { src: images.IconLikeGrey, alt: "icon-unliked" },
  fillWhite: { src: images.IconLikeFillWhite, alt: "icon-like-fill-white" },
  fillGrey: { src: images.IconLikeFillGrey, alt: "icon-like-fill-grey" },
  fillBlue: { src: images.IconLikeFillBlue, alt: "icon-like-fill-blue" },
};
const ICON_SHARE = {
  src: images.IconShare,
  alt: "icon-share",
};
const ICON_COMMENT = {
  src: images.IconComment,
  alt: "icon-comment",
};

const HEADER_TYPES = ["big", "tiny", "noheader"];

const FOOTER_TYPES = ["standart", "nofooter"];

const LOADING = "Herunterladen...";
const TXT_SHARE = "Artikel per e-mail teilen";

export default {
  PAGES_IDs,
  SUPPORT_HEADERS,
  DEFAULT_ARTICLES_CATEGORY_ID,
  ICON_BOOKMARKS,
  ICON_LIKE,
  ICON_SHARE,
  ICON_COMMENT,
  HEADER_TYPES,
  LOADING,
  FOOTER_TYPES,
  TXT_SHARE,
};
