import api from "./apiQueryUtils";
import env from "./globalVarsProcess";
import text from "./textProcess"
import user from "./userData"

export default {
  api,
  env,
  text,
  user
};
