import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./articleInfo.module.scss";
import constants from "./constants";

const ArticleInfo = ({ type, className, value }) => {
  return (
    <motion.div className={classNames(styles.container, className)}>
      <motion.img
        className={classNames(styles.icon)}
        src={
          type === "like"
            ? constants.ICONS.like.src
            : constants.ICONS.comment.src
        }
        alt={
          type === "like"
            ? constants.ICONS.like.alt
            : constants.ICONS.comment.alt
        }
      />
      <motion.p className={classNames(styles.value)}>{value}</motion.p>
    </motion.div>
  );
};

ArticleInfo.propTypes = {
  type: PropTypes.oneOf(["like", "comment"]).isRequired,
  className: PropTypes.string,
  value: PropTypes.number,
};

ArticleInfo.defaultProps = {
  className: "",
  value: 0,
};

export { ArticleInfo };
