import global from "../../constants/global";

const PAGE_ID = global.PAGES_IDs[3];

const HEADER_TYPE = global.HEADER_TYPES[0];

export default {
  PAGE_ID,
  HEADER_TYPE,
};
