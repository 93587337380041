import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { UserForm } from "../../shared/components/UserForm/UserForm";
import useRedirects from "../../hooks/redirects/redirects";
import { login } from "../../features/user.operations";
import scheems from "../../schemes/index";
import utils from "../../utils";
import constants from "./constants";

const LoginPage = ({ setHeaderType, setFooterType }) => {
  const [succesResult, setSuccesResult] = useState(false);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const { toHomepage } = useRedirects();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.user);
  // const { mouseLeave } = useFillHint();

  const handleLogin = (formValue) => {
    setLoadingInProcess(true);
    const { username, password, rememberme } = formValue;
    if (rememberme && Array.isArray(rememberme) && rememberme.length) {
      utils.user.setEnterData(username, password);
    }

    dispatch(login({ username, password }))
      .unwrap()
      .then(
        () => {
          setSuccesResult(true);
          // const result =  toLastVisitedPage();
          const result = toHomepage();
          console.log(result);
          if (result) return result;
          setLoadingInProcess(false);
        },
        (reason) => {
          console.log(reason);
          setLoadingInProcess(false);
        }
      );
  };

  // useEffect(() => {
  //   // mouseLeave();
  // }, []);

  useEffect(() => {
    setHeaderType(constants.HEADER_TYPE);
    setFooterType(constants.FOOTER_TYPE);
    if (isLoggedIn) {
      toHomepage();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      toHomepage();
    }
  }, [isLoggedIn]);

  return (
    <UserForm
      handler={handleLogin}
      fields={constants.FIELDS}
      buttonName={constants.BUTTON_NAME}
      subtitle={constants.SUBTITLE}
      verificationSheme={scheems.userLoginFormSchems}
      succesResult={succesResult}
      className={constants.THEME_CLASS}
      loadingInProcess={loadingInProcess}
      setLoadingInProcess={setLoadingInProcess}
    >
      {constants.LINKS &&
        constants.LINKS.map((link, index) => (
          <motion.div key={index} dangerouslySetInnerHTML={{ __html: link }} />
        ))}
    </UserForm>
  );
};

export { LoginPage };
