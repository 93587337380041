import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { Preloader } from "../Preloader/Preloader";
import { Container } from "../Markup/Container/Container";
import { StandartButtons } from "../Buttons/StandartButtons/StandartButtons";
import constants from "./constants";
import styles from "./userForms.module.scss";

const UserForm = ({
  fields,
  handler,
  buttonName = "",
  subtitle = "",
  children,
  verificationSheme,
  succesResult,
  className,
  loadingInProcess = false,
  setLoadingInProcess = () => {},
}) => {
  const submitButton = useRef();
  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    if (message) {
      setLoadingInProcess(false);
    }
  }, [message]);

  const clickDecoratoionButton = () => {
    submitButton.current.click()
  };

  return (
    <>
      {loadingInProcess && (
        <Preloader litenTheme={true}>
          <motion.div>{constants.LOADING}</motion.div>
        </Preloader>
      )}
      <Container
        className={classNames(
          styles.container,
          styles.darkBlue,
          className,
          constants.THEME_CLASS
        )}
        style={{ backgroundColor: constants.DEFAULT_COLOR }}
      >
        <motion.div className={styles.formContainer}>
          <motion.div className={styles.fieldsArea}>
            <motion.div className={styles.formTitle}>
              {constants.TITLE}
            </motion.div>
            <motion.div className={styles.subTitle}>{subtitle}</motion.div>
            <Formik
              initialValues={fields.reduce((res, field) => {
                res[field.name] = field.default;
                return res;
              }, {})}
              validationSchema={verificationSheme}
              onSubmit={handler}
            >
              {(props) => {
                return (
                  <Form>
                    {
                      fields.length > 0 &&
                        fields.map((field, index) => (
                          // field.type == "password" ? (
                          //   <PasswordField
                          //     field={field}
                          //     index={index}
                          //     value={props.values[field.name]}
                          //     props={props}
                          //   />
                          // ) : (
                          <div
                            className={styles.inputContainer}
                            key={`field-${index}`}
                          >
                            {/* <Tooltip title={field.tooltip} placement="right"> */}
                            <motion.div
                              className={
                                field.type == "checkbox"
                                  ? styles.checkboxSquare
                                  : ""
                              }
                            >
                              <Field
                                id={field.name}
                                name={field.name}
                                type={field.type}
                                placeholder={field.placeholder}
                                value={
                                  field.type == "checkbox"
                                    ? field.value
                                    : props.values[field.name]
                                }
                              />
                            </motion.div>
                            {/* </Tooltip> */}
                            {field.value && (
                              <label
                                className={styles.label}
                                htmlFor={field.name}
                              >
                                {field.value}
                              </label>
                            )}
                            {props.errors[field.name] ? (
                              <motion.div className="error">
                                <span>{props.errors[field.name]}</span>
                              </motion.div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                      //   )
                    }
                    <div className={styles.invisible}>
                      <input ref={submitButton} type="submit" />
                    </div>
                    <StandartButtons
                      title={buttonName}
                      onClick={clickDecoratoionButton}
                    />
                    {message && (
                      <motion.div
                        className={classNames(
                          styles.error,
                          succesResult ? styles.success : ""
                        )}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: message,
                          }}
                        />
                      </motion.div>
                    )}
                  </Form>
                );
              }}
            </Formik>
            <motion.div className={styles.linksArea}>{children}</motion.div>
          </motion.div>
        </motion.div>
      </Container>
    </>
  );
};

UserForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  handler: PropTypes.func.isRequired,
  buttonName: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object),
  verificationSheme: PropTypes.object,
  // succesResult: PropTypes.string,
  className: PropTypes.string,
  loadingInProcess: PropTypes.bool,
  setLoadingInProcess: PropTypes.func,
};

UserForm.defaultProps = {
  buttonName: "",
  subtitle: "",
  loadingInProcess: false,
  setLoadingInProcess: () => {},
};

export { UserForm };
