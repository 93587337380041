import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./like.module.scss";
import constants from "./constants";
import utils from "../../../../utils";
import api from "../../../../api/server.api";
import crud from "../../../../api/crud";

const Like = ({ liked, className, userId, postId, successCallback }) => {
  const [likeUsers, setLikeUsers] = useState([]);
  const [hoverState, setHoverState] = useState(false);
  const headerWithAuth = utils.user.headerWithAuth();

  useEffect(() => {
    if (likeUsers) successCallback(likeUsers.map((v) => Number(v)));
  }, [likeUsers]);

  const onClickLike = async () => {
    try {
      await Promise.all([
        utils.api.postData(
          api.custom,
          crud.custom.ARTICLE_LIKE,
          setLikeUsers,
          {
            userId,
            postId,
          },
          headerWithAuth
        ),
      ]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <motion.div
      className={classNames(
        styles.container,
        className,
        liked ? styles.container__liked : styles.container__default
      )}
      {...constants.ANIMATIONS.container}
      onMouseLeave={() => setHoverState(false)}
      onMouseOver={() => setHoverState(true)}
      onClick={onClickLike}
    >
      <motion.img
        {...constants.ANIMATIONS.icon}
        animate={hoverState ? "end" : "start"}
        className={classNames(styles.icon)}
        src={
          liked || hoverState
            ? constants.ICON_LIKE.fillWhite.src
            : constants.ICON_LIKE.fillBlue.src
        }
        alt={
          liked || hoverState
            ? constants.ICON_LIKE.fillWhite.alt
            : constants.ICON_LIKE.fillBlue.alt
        }
      />
    </motion.div>
  );
};

Like.propTypes = {
  liked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  userId: PropTypes.number.isRequired,
  postId: PropTypes.number.isRequired,
  successCallback: PropTypes.func,
};

Like.defaultProps = {
  className: "",
  successCallback: () => {},
};

export { Like };
