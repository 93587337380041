import animation from "../../../constants/animation";
import global from "../../../constants/global";

const ICON_BOOKMARKS = global.ICON_BOOKMARKS;

const ANIMATIONS = {
  card: {
    active: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 0.3, 0) },
    hover: { ...animation.HOVER_BACKGROUND_CHANGE(0.5, 0) },
  },
  image: { ...animation.SCALE_ANIMATION(1, 1.1, 0.5, 0) },
  subtitle: { ...animation.FADEIN_APPEARING_VIEWPORT(50, 0, 0.5, 0) },
  title: { ...animation.FADEIN_APPEARING_VIEWPORT(100, 0, 0.5, 0.3) },
  description: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 100, 0.5, 0.5) },
};

export default {
  ICON_BOOKMARKS,
  ANIMATIONS,
};
