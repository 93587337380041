import animation from "../../../../constants/animation";
import global from "../../../../constants/global";

const ICON_LIKE = global.ICON_LIKE;

const ANIMATIONS = {
  container: {
    ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 0.5, 0.3),
  },
  icon: { ...animation.SCALE_ANIMATION(1, 1.1, 0.5, 0) },
};

export default {
  ICON_LIKE,
  ANIMATIONS,
};
