import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import useMenu from "../../hooks/menu/menu";
import constants from "./constants";

const FaqPage = ({ setActiveMenuItem, setHeaderType }) => {
  const { resetMenu } = useMenu();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    resetMenu();
    setActiveMenuItem(constants.PAGE_ID);
    setHeaderType(constants.HEADER_TYPE);
    try {
      //server querry
    } catch (e) {
      //error helding
    } finally {
      setIsLoading(false);
    }
  }, []);

  return isLoading && <Preloader />;
};

FaqPage.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func,
};

FaqPage.defaultProps = { setActiveMenuItem: () => {}, setHeaderType: () => {} };

export { FaqPage };
