const decodeHtmlCharCodes = (str) => {
  return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
    return String.fromCharCode(charCode);
  });
};

const convertStringToArray = (str) => {
  return JSON.parse(str).map(Number);
};

export default {
  decodeHtmlCharCodes,
  convertStringToArray,
};
