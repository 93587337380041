import global from "../../constants/global";

const MENU_ITEMS = [
  {
    title: "Blickpunkt",
    subtitle: "PROJEKTBLOG BETRIEBSWIRTSCHAFTLICHE ANWENDUNGEN",
    link: "/",
    id: global.PAGES_IDs[0],
  },
  {
    title: "Wissen",
    subtitle: "",
    link: "/wissen",
    id: global.PAGES_IDs[1],
  },
  {
    title: "Projekte im Programm",
    subtitle: "",
    link: "/projekte-im-programm",
    id: global.PAGES_IDs[2],
    submenu: [
      {
        title: "Button title",
        link: "/",
        submenu: [
          {
            title: "Button title",
            link: "/",
          },
          {
            title: "Button title",
            link: "/",
          },
          {
            title: "Button title",
            link: "/",
            submenu: [
              {
                title: "Button title",
                link: "/",
              },
              {
                title: "Button title",
                link: "/",
              },
            ],
          },
        ],
      },
      {
        title: "Button title",
        link: "/",
      },
      {
        title: "Button title",
        link: "/",
      },
    ],
  },
  {
    title: "FAQ",
    link: "/faq",
    subtitle: "",
    id: global.PAGES_IDs[3],
  },
  {
    title: "Agil-Methodik",
    subtitle: "",
    link: "/agil-methodik",
    id: global.PAGES_IDs[4],
  },
];

const HEADER_TYPES = global.HEADER_TYPES;
const FOOTER_TYPES = global.FOOTER_TYPES;

export default {
  MENU_ITEMS,
  HEADER_TYPES,
  FOOTER_TYPES
};
