import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./share.module.scss";
import constants from "./constants";

const Share = ({ text, className, email }) => {
  return (
    <motion.a
      href={`mailto:${email}`}
      className={classNames(styles.container, className)}
      {...constants.ANIMATIONS.container}
    >
      <motion.p className={classNames(styles.text)}>{text}</motion.p>
      <motion.img
        className={classNames(styles.icon)}
        {...constants.ICON_SHARE}
      />
    </motion.a>
  );
};

Share.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  email: PropTypes.string,
};

Share.defaultProps = {
  text: "",
  className: "",
  email: "",
};

export { Share };
