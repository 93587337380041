import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserForm } from "../../shared/components/UserForm/UserForm";
import { resetPassword } from "../../features/user.operations";
import { setMessage } from "../../features/message";
import useRedirects from "../../hooks/redirects/redirects";
import scheems  from "../../schemes/index"
import constants from "./constants";


const ResetPasswordPage = ({setHeaderType, setFooterType}) => {
  const [succesResult, setSuccesResult] = useState(false);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.user);
  const { toHomepage } = useRedirects();
  // const { toLastVisitedPage, toHomepage } = useRedirects();
  const dispatch = useDispatch();
  // const { mouseLeave } = useFillHint();

  const handleResetPassword = (formValue) => {
    setLoadingInProcess(true);
    const { username, useremail, password } = formValue;

    dispatch(resetPassword({ username, useremail, password }))
      .unwrap()
      .then(() => {
        formValue.username = constants.DEFAULT_VALUE;
        setSuccesResult(true);
        dispatch(setMessage(constants.SUCCESS_MESSAGE));
      });
  };

  // useEffect(() => {
  //   // mouseLeave();
  // }, []);

  // if (toHomepage()) {
  //   return toHomepage();
  // }

  useEffect(() => {
    setHeaderType(constants.HEADER_TYPE);
    setFooterType(constants.FOOTER_TYPE)
    if(isLoggedIn){
        toHomepage();
    }
  }, []);

  return (
    <UserForm
      handler={handleResetPassword}
      fields={constants.FIELDS}
      buttonName={constants.BUTTON_NAME}
      verificationSheme={scheems.userResetPasswordFormSchems}
      succesResult={succesResult}
      loadingInProcess={loadingInProcess}
      setLoadingInProcess={setLoadingInProcess}
    >
      {constants.LINKS &&
        constants.LINKS.map((link, index) => (
          <motion.div key={index} dangerouslySetInnerHTML={{ __html: link }} />
        ))}
    </UserForm>
  );
};

export { ResetPasswordPage };
