import PropTypes from "prop-types";
import { motion } from "framer-motion";
import constants from "./constants";
import styles from "./supportHeader.module.scss";
const SupportingHead = ({ type }) => {
  return (
    <motion.div className={styles.container}>
      <motion.div className={styles.icon}>
        <img
          src={constants.HEADER_TYPES[type].img.src}
          alt={constants.HEADER_TYPES[type].img.src}
        />
      </motion.div>
      <motion.div className={styles.title}>
        {constants.HEADER_TYPES[type].title}
      </motion.div>

      <motion.div variants={constants.ANIMATION.variants} initial={"start"} animate={"end"} transition={constants.ANIMATION.transition} className={styles.underline}></motion.div>
    </motion.div>
  );
};

SupportingHead.propTypes = {
  type: PropTypes.string,
};

SupportingHead.defaultProps = {
  type: constants.DEFAULT_TYPE,
};

export { SupportingHead };
