import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container } from "../../components/Markup/Container/Container";
import { Row } from "../../components/Markup/Row/Row";
import { Col } from "../../components/Markup/Col/Col";
import constants from "./constants";
import styles from "./footer.module.scss";

const Footer = ({type}) => {
  return type != "nofooter" && (
    <section className={styles.container}>
      <Container>
        <Link to={"/"}>
          <motion.img
            {...constants.LOGOS.ukhd}
            {...constants.ANIMATIONS.logoUkhd}
          />
        </Link>
      </Container>
      <Container className={styles.zdiContainer}>
        <Row>
          <Col numb={6}>
            <Link to={"/"}>
              <motion.img
                {...constants.LOGOS.zdi}
                {...constants.ANIMATIONS.logoZdi}
              />
            </Link>
          </Col>
          <Col numb={6}>
            <motion.div className={styles.titles}>
              <Link to="/">
                <motion.h3 {...constants.ANIMATIONS.strTitle}>
                  {constants.STR_TITLE}
                </motion.h3>
              </Link>
              <motion.h3 {...constants.ANIMATIONS.strCopyright}>
                {constants.STR_COPYRIGHT}
              </motion.h3>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

Footer.propTypes = {
  type: PropTypes.oneOf(constants.FOOTER_TYPES)
}

Footer.deaultProps = {
  type: constants.FOOTER_TYPES[0]
}

export { Footer };
