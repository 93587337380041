import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence, useInView } from "framer-motion";
import useMenu from "../../hooks/menu/menu";
import { Container } from "../../shared/components/Markup/Container/Container";
import { Row } from "../../shared/components/Markup/Row/Row";
import { Col } from "../../shared/components/Markup/Col/Col";
import { Card } from "../../shared/components/Card/Card";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { Sidebar } from "../../shared/components/Sidebar/Sidebar";
import { CategoryHeading } from "../../shared/components/SimpleComponents/CategoryHeading/CategoryHeading";
import constants from "./constants";
import utils from "../../utils";
import api from "../../api/server.api";
import crud from "../../api/crud";
import styles from "./homePage.module.scss";

const HomePage = ({ setActiveMenuItem, setHeaderType }) => {
  const { resetMenu } = useMenu();
  const [isLoading, setIsLoading] = useState(true);
  const latestNews = useRef(null);
  const isInView = useInView(latestNews, { once: true });
  const [latestArticles, setLatestArticles] = useState([]);
  const [articlesInfo, setArticlesInfo] = useState(null);
  const [tagList, setTagList] = useState([]);

  const userId = Number(utils.user.getUserID());

  const loadPageInformation = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        utils.api.getData(
          api.custom,
          `${crud.custom.ARTICLES_LATEST}?per_page=${constants.ARTICLES_COUNT}&category=${constants.DEFAULT_ARTICLES_CATEGORY_ID}`,
          setArticlesInfo
        ),
        utils.api.getData(api.custom, crud.custom.ARTICLE_TAGS, setTagList),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    resetMenu();
    setActiveMenuItem(constants.PAGE_ID);
    setHeaderType(constants.HEADER_TYPE);
    loadPageInformation();
  }, []);

  useEffect(() => {
    if (articlesInfo) setLatestArticles([...articlesInfo.articles]);
  }, [articlesInfo]);

  return (
    <>
      {isLoading && <Preloader />}
      <motion.section ref={latestNews} className={styles.latestNewsSection}>
        <Container>
          <Row>
            <Col numb={8}>
              <Container fluid={true}>
                <Row>
                  <Col numb={6}>
                    <div className={styles.title}>
                      <CategoryHeading text="Aktuelles" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col numb={6}>
                    {latestArticles.map((latestArticle, index) => {
                      if (index % 2 === 0) {
                        return (
                          <div key={index}>
                            <AnimatePresence>
                              <motion.div
                                variants={constants.ANIMATION.variants}
                                initial="start"
                                animate={isInView ? "end" : "start"}
                                exit="start"
                                transition={constants.ANIMATION.transition(
                                  index * constants.CARDS_ANIMATION_DEPLAY
                                )}
                              >
                                <Card {...latestArticle} userId={userId} />
                              </motion.div>
                            </AnimatePresence>
                          </div>
                        );
                      }
                    })}
                  </Col>
                  <Col numb={6}>
                    {latestArticles.map((latestArticle, index) => {
                      if (index % 2 === 1) {
                        return (
                          <div key={index}>
                            <AnimatePresence>
                              <motion.div
                                variants={constants.ANIMATION.variants}
                                initial="start"
                                animate={isInView ? "end" : "start"}
                                exit="start"
                                transition={constants.ANIMATION.transition(
                                  index * constants.CARDS_ANIMATION_DEPLAY
                                )}
                              >
                                <Card {...latestArticle} userId={userId} />
                              </motion.div>
                            </AnimatePresence>
                          </div>
                        );
                      }
                    })}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col numb={4}>
              <Sidebar tags={tagList} />
            </Col>
          </Row>
        </Container>
      </motion.section>
    </>
  );
};

HomePage.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func,
};

HomePage.defaultProps = {
  setActiveMenuItem: () => {},
  setHeaderType: () => {},
};

export { HomePage };
