import React, { useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import classNames from "classnames";

import styles from "./tagFilter.module.scss";
import constants from "./constants";
import { Link } from "react-router-dom";

const TagFilter = ({ tag, list }) => {
  const [openState, setOpenState] = useState(false);

  return (
    <motion.div className={classNames(styles.container)}>
      <motion.div
        className={classNames(styles.valueItem)}
        onClick={() => {
          setOpenState(!openState);
        }}
      >
        <motion.h3>{list.filter((l) => l.slug === tag)?.[0]?.title}</motion.h3>
        <motion.img
          {...constants.ARROW_ICON}
          animate={openState ? "end" : "start"}
          variants={constants.ANIMATIONS.arrow.variants}
          transition={constants.ANIMATIONS.arrow.transition()}
        />
      </motion.div>
      <motion.div
        className={classNames(styles.list)}
        variants={constants.ANIMATIONS.listContainer.variants}
        initial="start"
        animate={openState ? "end" : "none"}
        exit="start"
        transition={constants.ANIMATIONS.listContainer.transition(0)}
      >
        {list.map((l, idx) => (
          <Link
            to={l.slug === "all" ? "/wissen" : `/wissen/tag/${l.slug}`}
            key={idx}
          >
            <motion.div
              className={classNames(
                styles.item,
                l.slug === tag ? styles.active : ""
              )}
              initial="start"
              variants={constants.ANIMATIONS.listItem.active.variants}
              transition={constants.ANIMATIONS.listItem.active.transition(
                idx * constants.ITEM_DELAY
              )}
              animate={openState ? "end" : "start"}
              onClick={() => setOpenState(false)}
            >
              {l.title}
            </motion.div>
          </Link>
        ))}
      </motion.div>
    </motion.div>
  );
};

TagFilter.propTypes = {
  tag: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
};

TagFilter.defaultProps = {
  tag: "first",
  list: [
    { slug: "first", title: "First option" },
    { slug: "second", title: "Second option" },
    { slug: "third", title: "Third option" },
    { slug: "forth", title: "Forth option" },
    { slug: "fivth", title: "Fivth option" },
    { slug: "sixth", title: "Sixth option" },
    { slug: "seventh", title: "Seventh option" },
  ],
};

export { TagFilter };
