import animation from "../../../../constants/animation";
import global from "../../../../constants/global";

const ICON_BOOKMARKS = global.ICON_BOOKMARKS;

const ANIMATIONS = {
  icon: {
    ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 0.5, 0.3),
    ...animation.HOVER_SCALE_CHANGE(1.1, 0.5, 0),
  },
};

export default {
  ICON_BOOKMARKS,
  ANIMATIONS,
};
