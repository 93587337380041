import global from "../../../../constants/global";
import animation from "../../../../constants/animation";

const ANIMATION = { ...animation.FADEIN_LEFT_APPEARANCE() };

const SUBTITLE_ANIMATION_DELAY = 0.5;

const HEADER_TYPES = global.HEADER_TYPES;

export default {
  ANIMATION,
  SUBTITLE_ANIMATION_DELAY,
  HEADER_TYPES,
};
