import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";

import useMenu from "../../hooks/menu/menu";
import { Container } from "../../shared/components/Markup/Container/Container";
import { Row } from "../../shared/components/Markup/Row/Row";
import { Col } from "../../shared/components/Markup/Col/Col";
import { Card } from "../../shared/components/Card/Card";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { CategoryHeading } from "../../shared/components/SimpleComponents/CategoryHeading/CategoryHeading";
import { TagFilter } from "../../shared/components/TagFilter/TagFilter";
import { StandartButtons } from "../../shared/components/Buttons/StandartButtons/StandartButtons";
import utils from "../../utils";
import api from "../../api/server.api";
import crud from "../../api/crud";
import constants from "./constants";
import styles from "./postListPage.module.scss";

const PostsListPage = ({ setActiveMenuItem = () => {}, setHeaderType }) => {
  const { resetMenu } = useMenu();
  const { tag } = useParams();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesInfo, setArticlesInfo] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [spinnerColor, setSpinnerColor] = useState("#ffffff");

  const userId = Number(utils.user.getUserID());

  useEffect(() => {
    resetMenu();
    setActiveMenuItem(constants.PAGE_ID);
    setHeaderType(constants.HEADER_TYPE);
    setSpinnerColor(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--outlined-button-color"
      )
    );
  }, []);

  useEffect(() => {
    setPageNumber(1);
    loadInitialData();
  }, [tag]);

  useEffect(() => {
    if (pageNumber > 1) loadMoreArticles();
  }, [pageNumber]);

  const loadInitialData = async () => {
    setIsLoadingPage(true);
    try {
      await Promise.all([
        utils.api.getData(api.custom, crud.custom.ARTICLE_TAGS, setTagList),
        utils.api.getData(
          api.custom,
          `${crud.custom.ARTICLES_LATEST}?per_page=${
            constants.ARTICLES_COUNT_PER_PAGE
          }&category=${constants.DEFAULT_ARTICLES_CATEGORY_ID}&tag=${
            tag ? tag : "all"
          }`,
          setArticlesInfo
        ),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingPage(false);
    }
  };

  const loadMoreArticles = async () => {
    setIsLoadingMore(true);
    try {
      await Promise.all([
        utils.api.getData(
          api.custom,
          `${crud.custom.ARTICLES_LATEST}?per_page=${
            constants.ARTICLES_COUNT_PER_PAGE
          }&page=${pageNumber}&category=${
            constants.DEFAULT_ARTICLES_CATEGORY_ID
          }&tag=${tag ? tag : "all"}`,
          setArticlesInfo
        ),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    if (articlesInfo) {
      setArticles((prevState) =>
        pageNumber === 1
          ? [...articlesInfo.articles]
          : [...prevState, ...articlesInfo.articles]
      );
      setTotalPage(articlesInfo.total_pages);
    }
  }, [articlesInfo]);

  return (
    <>
      {isLoadingPage && <Preloader />}
      <motion.section className={classNames(styles.container)}>
        <Container>
          <motion.div className={classNames(styles.title)}>
            <CategoryHeading text="Aktuelles" />
            {tagList.length > 0 && (
              <TagFilter
                tag={tag ? tag : "all"}
                list={[{ slug: "all", title: "All" }, ...tagList]}
              />
            )}
          </motion.div>
          <Row>
            <Col numb={4}>
              {articles.map((article, idx) => {
                return (
                  idx % 3 === 0 && (
                    <Card key={idx} {...article} userId={userId} />
                  )
                );
              })}
            </Col>
            <Col numb={4}>
              {articles.map((article, idx) => {
                return (
                  idx % 3 === 1 && (
                    <Card key={idx} {...article} userId={userId} />
                  )
                );
              })}
            </Col>
            <Col numb={4}>
              {articles.map((article, idx) => {
                return (
                  idx % 3 === 2 && (
                    <Card key={idx} {...article} userId={userId} />
                  )
                );
              })}
            </Col>
          </Row>
          <motion.div className={classNames(styles.loadMoreContainer)}>
            {pageNumber < totalPage && (
              <StandartButtons
                title={
                  isLoadingMore ? (
                    <MetroSpinner
                      size={26}
                      color={spinnerColor}
                      loading={isLoadingMore}
                    />
                  ) : (
                    "Mehr laden"
                  )
                }
                mode="outlined"
                onClick={() => {
                  return isLoadingMore
                    ? 0
                    : setPageNumber((prevState) => prevState + 1);
                }}
              />
            )}
          </motion.div>
        </Container>
      </motion.section>
    </>
  );
};

PostsListPage.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func,
};

PostsListPage.defaultProps = {
  setActiveMenuItem: () => {},
  setHeaderType: () => {},
};

export { PostsListPage };
