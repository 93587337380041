import { useEffect, useState } from "react";
import { Header } from "../../shared/components/HeaderComponents/Header/Header";
import constants from "./constants";
import { Router } from "../../shared/components/Router/Router";
import { Footer } from "../../shared/sections/Footer/Footer";

const DefaultPage = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(
    constants.MENU_ITEMS[0].id
  );
  const [headerType, setHeaderType] = useState(constants.HEADER_TYPES[0]);
  const [footerType, setFooterType] = useState(constants.FOOTER_TYPES[0]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [window.location.href]);

  const findTitle = (id) => {
    return constants.MENU_ITEMS.reduce((res, item) => {
      if (id == item.id) {
        return item;
      }
      return res;
    }, {});
  };
  return (
    <main>
      <Header
        menuItems={constants.MENU_ITEMS}
        activeMenuItem={activeMenuItem}
        title={
          findTitle(activeMenuItem).title ? findTitle(activeMenuItem).title : ""
        }
        subtitle={
          findTitle(activeMenuItem).subtitle
            ? findTitle(activeMenuItem).subtitle
            : ""
        }
        type={headerType}
      ></Header>
      <Router
        setActiveMenuItem={setActiveMenuItem}
        setHeaderType={setHeaderType}
        setFooterType={setFooterType}
      />
      <Footer type={footerType} />
    </main>
  );
};

export { DefaultPage };
