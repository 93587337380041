import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserForm } from "../../shared/components/UserForm/UserForm";
import useRedirects from "../../hooks/redirects/redirects";
import { register, login } from "../../features/user.operations";
import { setMessage } from "../../features/message";
import scheems from "../../schemes/index";
// import utils from "../../utils";
import constants from "./constants";

const RegisterPage = ({ setHeaderType, setFooterType }) => {
  const [succesResult, setSuccesResult] = useState(false);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.user);
  const { toHomepage } = useRedirects();
  // const { toLastVisitedPage, toHomepage } = useRedirects();
  const dispatch = useDispatch();
  // const { mouseLeave } = useFillHint();

  const handleRegister = (formValue) => {
    setLoadingInProcess(true);
    const { username, email, password } = formValue;

    dispatch(register({ username, email, password }))
      .unwrap()
      .then(() => {
        formValue.username = constants.DEFAULT_VALUE;
        formValue.email = constants.DEFAULT_VALUE;
        formValue.password = constants.DEFAULT_VALUE;
        formValue.repeatpassword = constants.DEFAULT_VALUE;
        setSuccesResult(true);
        dispatch(setMessage(constants.SUCCESS_MESSAGE));
        dispatch(login({ username: email, password }))
          .unwrap()
          .then(
            () => {
              setSuccesResult(true);
              // const result = toLastVisitedPage();
              const result = toHomepage();
              if (result) return result;
              setLoadingInProcess(false);
            },
            (reason) => {
              console.log(reason);
              setLoadingInProcess(false);
            }
          );
      }, (reason)=>{
        console.log(reason);
        setLoadingInProcess(false);
      });
  };

  // useEffect(() => {
  //   // mouseLeave();
  // }, []);

  // if (toHomepage()) {
  //   return toHomepage();
  // }

  useEffect(() => {
    setHeaderType(constants.HEADER_TYPE);
    setFooterType(constants.FOOTER_TYPE);
    if (isLoggedIn) {
      toHomepage();
    }
  }, []);


  useEffect(()=>{
    if(isLoggedIn){
        toHomepage();
    }
  }, [isLoggedIn])

  return (
    <UserForm
      handler={handleRegister}
      fields={constants.FIELDS}
      buttonName={constants.BUTTON_NAME}
      verificationSheme={scheems.userRegisterFormSchems}
      succesResult={succesResult}
      loadingInProcess={loadingInProcess}
      setLoadingInProcess={setLoadingInProcess}
    >
      {constants.LINKS &&
        constants.LINKS.map((link, index) => (
          <motion.div key={index} dangerouslySetInnerHTML={{ __html: link }} />
        ))}
    </UserForm>
  );
};

export { RegisterPage };
