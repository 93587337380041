import animation from "../../constants/animation";
import global from "../../constants/global";

const ANIMATION = { ...animation.FADEIN_APPEARING(2) };

const CARDS_ANIMATION_DEPLAY = 0.3;

const PAGE_ID = global.PAGES_IDs[0];
const TITLE = "Blickpunkt";

const ARTICLES_COUNT = 4;

const DEFAULT_ARTICLES_CATEGORY_ID = global.DEFAULT_ARTICLES_CATEGORY_ID;

const HEADER_TYPE = global.HEADER_TYPES[0];

export default {
  PAGE_ID,
  TITLE,
  ARTICLES_COUNT,
  ANIMATION,
  CARDS_ANIMATION_DEPLAY,
  DEFAULT_ARTICLES_CATEGORY_ID,
  HEADER_TYPE,
};
