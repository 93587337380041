import global from "../../../../constants/global";

const ICONS = {
  like: global.ICON_LIKE.fillGrey,
  comment: global.ICON_COMMENT,
};

export default {
  ICONS,
};
