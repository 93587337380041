import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./col.module.scss";
classNames;

const Col = ({ numb, children }) => {
  const getClassName = () => {
    switch (numb) {
      case 1:
        return styles.col__1;
      case 4:
        return styles.col__4;
      case 6:
        return styles.col__6;
      case 8:
        return styles.col__8;
      case 11:
        return styles.col__11;
      default:
        return styles.col__4;
    }
  };
  return (
    <div className={classNames(getClassName(), styles.col)}>{children}</div>
  );
};

Col.propTypes = {
  numb: PropTypes.number,
};

export { Col };
