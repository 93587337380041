import animation from "../../../../constants/animation";
import global from "../../../../constants/global";

const ICON_SHARE = global.ICON_SHARE;

const ANIMATIONS = {
  container: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 0.5, 0.3) },
};

export default {
  ICON_SHARE,
  ANIMATIONS,
};
