const HOST = `${window.location.hostname}${
  window.location.port ? ":" + window.location.port : ""
}`;

const ZDI_SETTINGS = {
  TAG: 'zdi',
  // HOME_LINK_TEXT: 'ZDI',
  // SUBTITLE : 'SAC Projekthandbuch',
  // SUBTITLE: 'Fachhandbuch SAC',
  // TITLE: 'Wiki UKHD SAC Fachhandbuch',
  // LOGO_SIGN: 'sacSign',
  // DESCRIPTION_SIGN:
  //   'Freie Wissensdatenbank für Mitarbeitende: Hier können Sie Wikis erstellen, sie mit Informationen anreichern und zusammen mit Ihren Kollegen und Kolleginnen bearbeiten.',
  // COLOR: '#004A70',
  // TITLES_COLOR: '#004A70',
  // FOOTER: 'ukhd',
  // FAVICON: 'ukhd',
  // VERSION: 'ukhd',
  // EXCLUDED_CATEGORIES: [1, 6],
};

//for local tests
const ZDI = {
  ...ZDI_SETTINGS,
  URL_STANDART: "https://zdi.wissen-lernen.eu/wp-json/wp/v2",
  URL_CUSTOM: "https://zdi.wissen-lernen.eu/",
  // HOME_LINK_TEXT: 'UKHD<span>WIKI</span>',
  // LOGO_SIGN: 'wikiSign',
  // DESCRIPTION_SIGN:
  //   'Freie Wissensdatenbank für Mitarbeitende: Hier können Sie Wikis erstellen, sie mit Informationen anreichern und zusammen mit Ihren Kollegen und Kolleginnen bearbeiten.',
  // COLOR: '#004A70',
  // TITLES_COLOR: '#004A70',
  // FOOTER: 'ukhd',
  // EXCLUDED_CATEGORIES: [1, 6],
  // MAX_LOADING_FILE_SIZE: 2097152,
  // VERSION: 'ukhd'
};

//   const ZDI_TEST = {
//     ...ZDI_SETTINGS,
//     URL_STANDART: 'http://23.88.54.192:8082/wp-json/wp/v2',
//     URL_CUSTOM: 'http://23.88.54.192:8082/',
//     MAX_LOADING_FILE_SIZE: 2097152,
//     TECH_ADMIN_ID: 1,
//   }

const EGK = {
  ...ZDI_SETTINGS,
  URL_STANDART: "https://zdi.wissen-lernen.eu/wp-json/wp/v2",
  URL_CUSTOM: "https://zdi.wissen-lernen.eu/",
  // HOME_LINK_TEXT: 'UKHD<span>WIKI</span>',
  // LOGO_SIGN: 'wikiSign',
  // DESCRIPTION_SIGN:
  //   'Freie Wissensdatenbank für Mitarbeitende: Hier können Sie Wikis erstellen, sie mit Informationen anreichern und zusammen mit Ihren Kollegen und Kolleginnen bearbeiten.',
  // COLOR: '#004A70',
  // TITLES_COLOR: '#004A70',
  // FOOTER: 'ukhd',
  // EXCLUDED_CATEGORIES: [1, 6],
  // MAX_LOADING_FILE_SIZE: 2097152,
  // VERSION: 'ukhd'
};

//   const ZDI_TEST = {
//     ...ZDI_SETTINGS,
//     URL_STANDART: 'http://23.88.54.192:8082/wp-json/wp/v2',
//     URL_CUSTOM: 'http://23.88.54.192:8082/',
//     MAX_LOADING_FILE_SIZE: 2097152,
//     TECH_ADMIN_ID: 1,
//   }

const VARIABLES = {
  prod: {
    "zdi-blog.wissen-lernen.eu": ZDI,
    "blog-egk.wissen-lernen.eu": EGK,
  },
  dev: {
    "localhost:3000": ZDI,
  },
  // stage: {
  //   'test.wiki-ukhd.wissen-lernen.eu':ZDI_TEST,
  // },
};

export default {
  VARIABLES,
  HOST,
};
